import React from 'react';
import Button from "../../components/Button";
import mockup1 from "../../assets/images/Home/mockup1.png";
import appStore from "../../assets/images/Home/appStore.png";
import "../../assets/styles/componentStyles/mobile.scss";
import { Bounce } from 'react-reveal';

const Mobile = () => {
    const gotoStore = () => {
        window.location.href = 'https://apps.apple.com/ng/app/studio-app-mobile/id6474139875';
    }

    return (
        <div className="card-mobile-BG">
            <div className="mobile-container mobile-BG">
                <Bounce right>
                    <div className="container">
                        <div className="d-flex row align-items-center justify-content-center mockup-section">
                            <div className="col-12 col-lg-6">
                                <img src={mockup1} alt='mockup1' />
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className='mobile-text'>
                                    The ultimate <br />satisfaction:
                                </div>
                                <div className='mobile-caption'>
                                    Explore the mobile experience no <br />matter where you go!
                                </div>
                                <div className="mt-3">
                                    <img src={appStore} alt='appStore' style={{ cursor: 'pointer' }} height={40} onClick={gotoStore} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Bounce>
            </div>
        </div>
    );
};

export default Mobile;
